import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/IP_Symcon/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IP Symcon Smarthome",
  "path": "/Software/Other_Platforms/IP_Symcon/Installation_on_a_RaspberryPi/",
  "dateChanged": "2019-04-25",
  "author": "Mike Polinowski",
  "excerpt": "The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras. A multitude of assistants and tools allow a comfortable configuration.",
  "social": "/images/Search/P_SearchThumb_Symcon.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IPSymcon_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "IP Symcon"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IP Symcon Smarthome' dateChanged='2019-04-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras. A multitude of assistants and tools allow a comfortable configuration.' image='/images/Search/P_SearchThumb_Symcon.png' twitter='/images/Search/P_SearchThumb_Symcon.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/IP_Symcon/Installation_on_a_RaspberryPi/' locationFR='/fr/Software/Other_Platforms/IP_Symcon/Installation_on_a_RaspberryPi/' crumbLabel="IP Symcon" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "ip-symcon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ip-symcon",
        "aria-label": "ip symcon permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Symcon`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preparation"
        }}>{`Preparation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#update"
        }}>{`Update`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#troubleshooting"
        }}>{`Troubleshooting`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`Currently IP-Symcon in version 5.x is available for `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/windows/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/macos/"
      }}>{`macOS`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/linux/"
      }}>{`Linux (Debian)`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/raspberry-pi/"
      }}>{`Raspberry Pi`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/qnap/"
      }}>{`QNAP`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/synology/"
      }}>{`Synology`}</a>{`.`}</p>
    <p>{`In the following we are going to go through the steps of installing the software on a Raspberry Pi 2 with the `}<a parentName="p" {...{
        "href": "https://www.raspberrypi.org/blog/buster-the-new-version-of-raspbian/"
      }}>{`Debian/Buster release of Raspbian`}</a>{`.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparation`}</h2>
    <p>{`Start with executing the following command and verify the correct time or set the time zone if needed:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`date`}</span></code></pre></div>
    <p>{`If the time zone needs to be set run the Raspberry Pi configuration tool to do so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` raspi-config`}</code></pre></div>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p>{`We start with adding the IP Symcon stable release to the APT package manager:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/apt/sources.list`}</code></pre></div>
    <p>{`Insert the following line at the end:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`deb http://apt.symcon.de/ stable rpi`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "679px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/db3fc34e7eef838672e668e49263813c/1b747/INSTAR_IP_Symcon_RasPi_Installation_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.347826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAuklEQVQI1w3L2wqCMAAAUH+7QuwhsrBICrooEhVC1FOXzbmpqZtjyaLsIcgK+5Q670dx57rr6NZQnZr1yaA2MxuzQd0eqe68s3G6G6u7tozlsOX0NKfftI2m3dcW4/Z2Ya4muoIDdEpxQIEf+iQBhCKSIBgevOh4xpADIgPOYSZQdovZU7DyTj9lWr3S6kWVMPbC1IsoPGAQJ77YA76LKD5JIQTnuchkzuSZ5YJdJSuurLiw8kG/b/rPPwGVfivEhNhoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db3fc34e7eef838672e668e49263813c/e4706/INSTAR_IP_Symcon_RasPi_Installation_01.avif 230w", "/en/static/db3fc34e7eef838672e668e49263813c/d1af7/INSTAR_IP_Symcon_RasPi_Installation_01.avif 460w", "/en/static/db3fc34e7eef838672e668e49263813c/a0bb4/INSTAR_IP_Symcon_RasPi_Installation_01.avif 679w"],
              "sizes": "(max-width: 679px) 100vw, 679px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/db3fc34e7eef838672e668e49263813c/a0b58/INSTAR_IP_Symcon_RasPi_Installation_01.webp 230w", "/en/static/db3fc34e7eef838672e668e49263813c/bc10c/INSTAR_IP_Symcon_RasPi_Installation_01.webp 460w", "/en/static/db3fc34e7eef838672e668e49263813c/8fbda/INSTAR_IP_Symcon_RasPi_Installation_01.webp 679w"],
              "sizes": "(max-width: 679px) 100vw, 679px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db3fc34e7eef838672e668e49263813c/81c8e/INSTAR_IP_Symcon_RasPi_Installation_01.png 230w", "/en/static/db3fc34e7eef838672e668e49263813c/08a84/INSTAR_IP_Symcon_RasPi_Installation_01.png 460w", "/en/static/db3fc34e7eef838672e668e49263813c/1b747/INSTAR_IP_Symcon_RasPi_Installation_01.png 679w"],
              "sizes": "(max-width: 679px) 100vw, 679px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/db3fc34e7eef838672e668e49263813c/1b747/INSTAR_IP_Symcon_RasPi_Installation_01.png",
              "alt": "INSTAR IP Symcon RasPi Installation",
              "title": "INSTAR IP Symcon RasPi Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add GPG-key:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` -qO - http://apt.symcon.de/symcon.key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` apt-key `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` -`}</code></pre></div>
    <p>{`Execute the following commands on the shell:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` symcon`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c7645a1e809702fa64cf3facf3e7f494/c5bb3/INSTAR_IP_Symcon_RasPi_Installation_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABs0lEQVQoz23Q246aUBQGYN//3rammUzVMUpxK6KEUWHDPrDZnEHOJ6Fv0kw0TS9M/qz8F+tLVtbk6i2O7k89XvnkM15+z8VZKsyi1TQRZonwI9+9Nadlr3y0p0Utz+vjvJZ/jeF56K2hIRPMN6azZgHwsRwtp+H6WyzMwo9paohZdM4DNbLlLFDbHNbptcthXxhDjYaWDC2ZBFx2LclnBwcDFwHX3HLjt4cBM0TPklwqRe7pFqhVDutcv7dk7OnQ0Sf26d5BwKN7qguM7CwMLLTlTKJI5JZEoeDSfeScEl+NnGPsKbGnFMnlibklMipyC3BjGx7n/uE9VpaJ+pFd1g3etQVsS6MpYJPDOoNVqn31Aj6xbi6veAHJiukgkt5v51WhCR3Ztwh0CPxp6djTsSNjR7/S00d5YgcBBkUGRcsQbLqPAtVzTmUOq9IoC1hl+mPvZSZZ+GmbokN2NgK2Kdpo62DA0ZZcN7GrPC68N3jsXmGX7iwocAyYKToYEG3jWVLIjz47hFyOXSWPzrdA7SrzBQ7YIbAPAZf/zcc/q1QrU628aUVyKZJrHp/7Gt0b/D/+CzFgz7h5h9f2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7645a1e809702fa64cf3facf3e7f494/e4706/INSTAR_IP_Symcon_RasPi_Installation_02.avif 230w", "/en/static/c7645a1e809702fa64cf3facf3e7f494/d1af7/INSTAR_IP_Symcon_RasPi_Installation_02.avif 460w", "/en/static/c7645a1e809702fa64cf3facf3e7f494/e3b5b/INSTAR_IP_Symcon_RasPi_Installation_02.avif 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c7645a1e809702fa64cf3facf3e7f494/a0b58/INSTAR_IP_Symcon_RasPi_Installation_02.webp 230w", "/en/static/c7645a1e809702fa64cf3facf3e7f494/bc10c/INSTAR_IP_Symcon_RasPi_Installation_02.webp 460w", "/en/static/c7645a1e809702fa64cf3facf3e7f494/35037/INSTAR_IP_Symcon_RasPi_Installation_02.webp 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7645a1e809702fa64cf3facf3e7f494/81c8e/INSTAR_IP_Symcon_RasPi_Installation_02.png 230w", "/en/static/c7645a1e809702fa64cf3facf3e7f494/08a84/INSTAR_IP_Symcon_RasPi_Installation_02.png 460w", "/en/static/c7645a1e809702fa64cf3facf3e7f494/c5bb3/INSTAR_IP_Symcon_RasPi_Installation_02.png 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c7645a1e809702fa64cf3facf3e7f494/c5bb3/INSTAR_IP_Symcon_RasPi_Installation_02.png",
              "alt": "INSTAR IP Symcon RasPi Installation",
              "title": "INSTAR IP Symcon RasPi Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The WebFront should be available under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://ipaddress:3777/`}</code>{`. You can find your Raspberry Pi's IP address by using the command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ip a`}</code>{`. Type in this IP address, followed by `}<strong parentName="p">{`:3777`}</strong>{`, into the address bar of your default browser to access the IP Symcon web frontend.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "893px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ae4bc9b3c2f068cc798f1197e875830/6c745/INSTAR_IP_Symcon_RasPi_Installation_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWElEQVQoz23O204aQRjA8XkAVLCWXUAKLnuEZYFhAVEBA7G2sWqNsaZqL9R6U9+lN00v2qQX1dBDVKAiKFGMLJCCQDiIKFo0fZNmoRJDm/wy+TLzn8wAE+808049ZzUa4SwLFy2OOZpjMUqto7UYpdXRWh2twSiNOFOc3QOHZ6BjxOn26U02IFWSUiUhVRI9CqJXhEtRvBslJHKsR0FIEF0XopOpqJZmqetRiL1MRYE+jaFPw7Y8aBoam3aNzzyefun0TY0/X/A8nUVxc+8jfTu7YwByzHJf34DZN7Xw7MXy/PL6xNzy5PyKb2pBSdoeDpg7SjlmAQgO75PjUEHa1IxDSdlQworgECX5jqYN9KqZDrJ+RtZPywdYzDgoU9P/Bm1AqqJaZP10t4LsRokulJAgOMYOGh1eFOMkCC5B8K7mfgegxXmcGcRIO6IykKzDAIdYOGK0uCw2D7SPmnmPyeo2Wd0cdLW1jjjoAgzrNnCjeqPHwo9tBT4mUl8OY/7U8Xb6ZDubDIpSoYwQLGTChcxeMRct5aLl/H65cFCrxIGZHyOZYYIZ5qBXON6pFWNZYec0FcgmA+VcpFaKXVQOG/VEoy7cXAm310lxbfrdSAP70ITV8cTAjXLQexDePM/vFVLBUvpHKbNbFt+JVIuxWuXw8uxIVI3Xq/HLqjj/ujgB3/0fdkOb4dBGOOy/SO/WPr+r+N+ffXp7GtnIpoOnQiCfDp0V9u8uHzXqiZurv78Aq2vrS69WF5dWXq+9iZ98zWS//RS2colgORcp5fbK+eh5MXZ7nfyvP9dxF4uolzxjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ae4bc9b3c2f068cc798f1197e875830/e4706/INSTAR_IP_Symcon_RasPi_Installation_03.avif 230w", "/en/static/8ae4bc9b3c2f068cc798f1197e875830/d1af7/INSTAR_IP_Symcon_RasPi_Installation_03.avif 460w", "/en/static/8ae4bc9b3c2f068cc798f1197e875830/796e0/INSTAR_IP_Symcon_RasPi_Installation_03.avif 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ae4bc9b3c2f068cc798f1197e875830/a0b58/INSTAR_IP_Symcon_RasPi_Installation_03.webp 230w", "/en/static/8ae4bc9b3c2f068cc798f1197e875830/bc10c/INSTAR_IP_Symcon_RasPi_Installation_03.webp 460w", "/en/static/8ae4bc9b3c2f068cc798f1197e875830/f0cd5/INSTAR_IP_Symcon_RasPi_Installation_03.webp 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ae4bc9b3c2f068cc798f1197e875830/81c8e/INSTAR_IP_Symcon_RasPi_Installation_03.png 230w", "/en/static/8ae4bc9b3c2f068cc798f1197e875830/08a84/INSTAR_IP_Symcon_RasPi_Installation_03.png 460w", "/en/static/8ae4bc9b3c2f068cc798f1197e875830/6c745/INSTAR_IP_Symcon_RasPi_Installation_03.png 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ae4bc9b3c2f068cc798f1197e875830/6c745/INSTAR_IP_Symcon_RasPi_Installation_03.png",
              "alt": "INSTAR IP Symcon RasPi Installation",
              "title": "INSTAR IP Symcon RasPi Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "update",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#update",
        "aria-label": "update permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Update`}</h2>
    <p>{`Update to the most current version`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` upgrade`}</code></pre></div>
    <p>{`How can I start and stop the IP-Symcon service?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo /etc/init.d/symcon start
sudo /etc/init.d/symcon stop
sudo /etc/init.d/symcon restart`}</code></pre></div>
    <h2 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h2>
    <ul>
      <li parentName="ul">{`How can I check if the service runs correctly?`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` symcon`}</code></pre></div>
    <ul>
      <li parentName="ul">{`How can I view/follow the log file?`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`tail -f /var/log/symcon/logfile.log`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      